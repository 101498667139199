import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { processErrorResponseInFunctional } from '../../components/errorBoundary/errorHandlingUtils';
import FilesUpload from '../../components/filesUpload';
import RoutePath from '../../components/routePaths';
import { uploadFileCheck } from '../../lib/fileUtils';
import { ReferralStatus } from '../../lib/types/referralStatus';
import useReferralService, { ImportResults } from '../../services/useReferralService';
import { referralActionTypes, StoreContext } from '../../store';
import { TabOptionKey } from './tabSelector';
import useDispatchFilter from './useDispatchFilter';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 999,
  },
  blur: {
    filter: 'blur(1px)',
    pointerEvents: 'none',
  },
  importResults: {
    margin: '0px 10px',
    width: 'auto',
  },
  resultTable: {
    minWidth: '500px !important',
  },
  autoDispatchAlert: { marginBottom: 16 },
}));

const fileCheckOptions = {
  allowedFileTypes: 'csv',
} as const;

type ImportDialogProps = {
  open: boolean;
  onClose: () => void;
  autoDispatch: boolean;
};

function ImportDialog({ open, onClose, autoDispatch }: ImportDialogProps) {
  const navigate = useNavigate();
  const [, dispatch] = useContext(StoreContext);
  const referralService = useReferralService();
  const { dispatchTab, dispatchRibbonFilters, dispatchReferralListFilters } = useDispatchFilter();

  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);
  const [enableImport, setEnableImport] = useState(false);
  const [importedResults, setImportedResults] = useState<ImportResults | null>(null);
  const [importError, setImportError] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setImportedResults(null);
      setFiles([]);
      setImportError(null);
      setEnableImport(false);
    }
  }, [open]);

  const handleChange = (fileList: File[]) => {
    setFiles(fileList);

    const fail = fileList.some(
      (file) => uploadFileCheck(file, fileCheckOptions).type !== 'success',
    );
    setEnableImport(!fail);

    setImportedResults(null);
    setImportError(null);
  };

  const handleImportPatients = () => {
    if (files.length > 0) {
      setLoading(true);
      setImportError(null);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append(file.name, file, file.name);
      });

      referralService
        .importPatients(formData)
        .then((results) => {
          dispatch({
            type: referralActionTypes.SEND_GP_REFERRAL_LIST_REQUEST,
            payload: true,
          });
          setImportedResults(results);
          setFiles([]);
        })
        .catch((error) => processErrorResponseInFunctional(error, setImportError))
        .finally(() => setLoading(false));
    }
  };

  const handleShowList = () => {
    dispatchTab(TabOptionKey.Todo);
    dispatchRibbonFilters(new Map([['workflow', [ReferralStatus.WaitingList]]]));
    dispatchReferralListFilters({
      page: 0,
      orderBy: 'waitlistDate',
      order: 'desc',
      filter: { state: [ReferralStatus.WaitingList] },
    });

    onClose();
    return navigate(RoutePath.ReferralListPatients);
  };

  const getImportErr = () => {
    if (importError != null && 'errors' in importError && importError.errors.length > 0) {
      return importError[0];
    }
    return null;
  };

  const importErr = getImportErr();
  const importErrStatus = importErr?.status;
  const importErrDetail = importErr?.detail;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" aria-labelledby="import-file-dialog">
      <div className={loading ? classes.blur : ''}>
        {loading && <CircularProgress className={classes.loader} />}
        <DialogTitle name="import-file-dialog-title">Import Patients</DialogTitle>
        <DialogContent style={{ fontSize: '1rem' }}>
          {importError && (
            <>
              <Typography variant="subtitle1" sx={{ color: 'error' }}>
                Patient Import Failed
              </Typography>
              <Typography variant="subtitle2">
                {importErr != null && importErrStatus === 401
                  ? importErrDetail
                  : 'Please check the import file is valid and try again'}
              </Typography>
            </>
          )}

          {autoDispatch && (
            <div className={classes.autoDispatchAlert}>
              <Alert severity="info">Health questionnaire will be dispatched automatically</Alert>
            </div>
          )}

          {!importedResults && !(importErrStatus === 401) && (
            <form>
              <FilesUpload
                files={files}
                onFilesChange={handleChange}
                multiple={false}
                allowedFileTypes={fileCheckOptions.allowedFileTypes}
              />
            </form>
          )}

          {importedResults && (
            <>
              <Typography variant="subtitle1">Patient Import Complete</Typography>
              <TableContainer component={Paper} className={classes.importResults}>
                <Table size="small" aria-label="a dense table" className={classes.resultTable}>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Patients Processed
                      </TableCell>
                      <TableCell align="right">{importedResults.totalRows}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Invalid Rows
                      </TableCell>
                      <TableCell align="right">{importedResults.invalidRows?.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <Tooltip
                        title="If the patient is already in the system (linked by UR), then skip loading that patient data, but load the referral"
                        arrow
                      >
                        <TableCell component="th" scope="row">
                          Synchronised Patients
                        </TableCell>
                      </Tooltip>

                      <TableCell align="right">{importedResults.updatedPatients}</TableCell>
                    </TableRow>
                    <TableRow>
                      <Tooltip
                        title="If the referral is already in the system (linked by the referral key) then skip loading the referral"
                        arrow
                      >
                        <TableCell component="th" scope="row">
                          Synchronised Referrals
                        </TableCell>
                      </Tooltip>
                      <TableCell align="right">{importedResults.updatedReferrals}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" name="import-cancel">
            Cancel
          </Button>
          {importedResults && (
            <Button onClick={() => handleShowList()} variant="contained" color="primary">
              Show Referrals List
            </Button>
          )}
          {!importedResults && importErrStatus !== 401 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleImportPatients}
              disabled={!enableImport}
            >
              Import
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}

ImportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportDialog;
